import { combineReducers, createAction, createReducer } from 'redux-starter-kit'
import Uri from 'urijs'
import { getCookie, setCookie } from '../cookies'
import challenges from './challenges'
import instruments from './instruments'
import rounds, { allRounds } from './rounds'

export const CLEAR = 'clear'
export const ALTERNATIVE = 'alternative'
export const FLASH_ERROR = 'ERROR'
export const FLASH_SUCCESS = 'SUCCESS'
export const PAGE_TRANSITION_TIME = 200
export const ANALYTICS_COOKIES_KEY = 'accepted_analytics'
export const INSTALL_COOKIES_KEY = 'notified_install'
export const ALTERNATIVE_SCREENS = [
  '/video/select',
  '/video/select/menu',
  '/video/verified',
  '/video/verified/menu',
  '/vote/confirmed'
]

/**
 * Returns if a pathname matches one of the Alternative screens
 * Defined alternative screen can have params like: /submission/details/:param
 * /submission/details/:param or /video/:param/delete/:param
 * @param {*} path
 */
export const isAlternativeScreen = path => ALTERNATIVE_SCREENS.includes(path)

const setBackgroundMode = createAction('SET-BACKGROUND-MODE')
const removeBackgroundMode = createAction('REMOVE-BACKGROUND-MODE')

const setFlash = createAction('SHOW-FLASH')
export const hideFlash = createAction('HIDE-FLASH')

let errorTimeout = null
const ERROR_TIME = 10000

export const showErrorFlash = payload => dispatch => {
  dispatch(showFlash({ ...payload, type: FLASH_ERROR }))
}

export const showSuccessFlash = payload => dispatch => {
  dispatch(showFlash({ ...payload, type: FLASH_SUCCESS }))
}

export const showFlash = payload => dispatch => {
  dispatch(setFlash(payload))

  if (errorTimeout) clearTimeout(errorTimeout)
  errorTimeout = setTimeout(() => {
    dispatch(hideFlash())
  }, ERROR_TIME)
}

const setAnalytics = createAction('SET-ANALYTICS')
const setInstall = createAction('SET-INSTALL')

export const allowAnalytics = payload => dispatch => {
  dispatch(setAnalytics(payload))
  setCookie(ANALYTICS_COOKIES_KEY, payload)
}

export const installSuggested = payload => dispatch => {
  setCookie(INSTALL_COOKIES_KEY, payload)
  dispatch(setInstall(payload))
}

const changeBackground = (className = null, add = true) => {
  const wrapper = document.querySelector('.loading-wrapper')
  if (wrapper) {
    if (add) wrapper.classList.add(className)
    else wrapper.classList.remove(className)
  }
}

export const setClearBackground = () => dispatch => {
  changeBackground(CLEAR)
}

export const removeClearBackground = dispatch => {
  changeBackground(CLEAR, false)
  dispatch(removeBackgroundMode(CLEAR))
}

export const setAlternativeBackground = dispatch => {
  changeBackground(ALTERNATIVE)
  dispatch(setBackgroundMode(ALTERNATIVE))
}

export const removeAlternativeBackground = dispatch => {
  changeBackground(ALTERNATIVE, false)
  dispatch(removeBackgroundMode(ALTERNATIVE))
}

export const onChangeLocation = path => (dispatch, getState) => {
  const {
    general: { backgroundMode }
  } = getState()
  const alternativeScreen = isAlternativeScreen(path)
  const alternativeBg = backgroundMode === ALTERNATIVE
  // We only want to set the BG to alternative if its the correct screen && its not applied already
  if (alternativeScreen) {
    if (!alternativeBg) dispatch(setAlternativeBackground)
  } else {
    dispatch(removeAlternativeBackground)
  }
}

const backgroundMode = createReducer(null, {
  [setBackgroundMode]: (state, action) => action.payload,
  [removeBackgroundMode]: (state, action) => null
})

export const checkMenuScroll = location => dispatch => {
  const isOpen = menuIsOpen(location)
  const body = document.querySelector('#root')
  if (body) {
    if (isOpen) body.classList.add('block-scroll')
    else body.classList.remove('block-scroll')
  }
}

const getUri = location => new Uri(location.pathname)

export const menuIsOpen = location => {
  const menuPath = getUri(location)
  return menuPath.segment().includes('menu')
}

const hasSteps = new RegExp('step', 'gi')

export const filterPaths = location => {
  const menuPath = getUri(location)
  menuPath.segment(
    menuPath.segment().filter(s => s !== 'menu' && !hasSteps.test(s))
  )
  return menuPath.readable()
}

export const filterMenuPath = location => {
  const menuPath = getUri(location)
  menuPath.segment(menuPath.segment().filter(s => s !== 'menu'))
  return menuPath.readable()
}

export const addMenuPath = location => {
  const menuPath = getUri(location)
  menuPath.segment('menu')
  return menuPath.readable()
}

export const openMenu = (history, location) => {
  history.push(addMenuPath(location))
}

export const closeMenu = (history, location) => () => {
  history.goBack()
}

export const closeMenuAndGo = (history, location, to) => () => {
  history.replace(to)
}

export const resetTopScroll = () => dispatch => {
  dispatch(scrollTo(0))
}

export const scrollTo = scroll => () => {
  setTimeout(() => {
    const body = document.querySelector('#root')
    if (body) {
      body.scroll(0, scroll)
    }
  }, PAGE_TRANSITION_TIME / 2)
}

const flashInitialState = { type: null, message: null, title: null }
const flash = combineReducers({
  show: createReducer(null, {
    [setFlash]: () => true,
    [hideFlash]: state => (state !== null ? false : null)
  }),
  content: createReducer(flashInitialState, {
    [setFlash]: (state, { payload }) => ({ ...payload })
  })
})

const analyticsCookie = getCookie(ANALYTICS_COOKIES_KEY)
const installCookie = getCookie(INSTALL_COOKIES_KEY)

const analytics = combineReducers({
  open: createReducer(analyticsCookie ? null : true, {
    [setAnalytics]: () => false
  }),
  accepted: createReducer(analyticsCookie, {
    [setAnalytics]: (state, { payload }) => payload
  })
})

const isStandalone =
  typeof window !== 'undefined' &&
  typeof window.matchMedia !== 'undefined' &&
  window.matchMedia('(display-mode: standalone)').matches
const isIOS =
  /iPhone|iPad|iPod|ios/i.test(navigator.userAgent) && !isStandalone
    ? true
    : null

const install = combineReducers({
  open: createReducer(installCookie ? null : analyticsCookie ? isIOS : null, {
    [setAnalytics]: () => (installCookie ? null : isIOS),
    [setInstall]: () => false
  }),
  notified: createReducer(installCookie, {
    [setInstall]: (state, { payload }) => payload
  })
})

const device = combineReducers({
  standalone: createReducer(isStandalone, {}),
  ios: createReducer(isIOS, {})
})

export default combineReducers({
  analytics,
  install,
  backgroundMode,
  flash,
  device,
  challenges,
  instruments,
  rounds,
  allRounds
})
