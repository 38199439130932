import { combineReducers } from 'redux-starter-kit'
import { createAsyncActions, createAsyncReducer } from 'resynchronize'
import { asyncDispatch } from '../utils'
import { formatSubmission } from './formatter'
import list from './list'
import views, { addView } from './views'
import vote from './vote'

const VIEW_TIME = 5000

export const actions = {
  detail: createAsyncActions('SUBMISSION-DETAIL')
}

let viewTimeout = null

export const cancelDetailView = () => () => {
  clearTimeout(viewTimeout)
}

export const getDetail = (id, noViewCount) => (dispatch, getState) => {
  if (!noViewCount) {
    dispatch(cancelDetailView())
  }

  return dispatch(
    asyncDispatch(actions.detail, `api/submissions/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(() => {
      if (!noViewCount) {
        viewTimeout = setTimeout(() => {
          dispatch(addView(id))
        }, VIEW_TIME)
      }
    })
    .catch(ex => {
      console.warn(ex)
    })
}

const detail = createAsyncReducer(actions.detail, {
  done: (state, { payload }) => {
    return formatSubmission(payload)
  }
})

export default combineReducers({
  list,
  vote,
  views,
  detail
})
