/* global fetch */
import _ from 'lodash'
import uri from 'urijs'
import { getAuth } from './cookies'
import { createAsyncReducer } from 'resynchronize'

const config = {
  APIPort: process.env.REACT_APP_API_PORT,
  APIUrl: process.env.REACT_APP_BASE_URL,
  ShareUrl: process.env.REACT_APP_WEB_URL
}

/**
 * Builds the API URL using the given path
 * @param {string} path API path
 * @return {string} API URL
 */
export const getAPIURL = (path = '') => {
  if (path && path.length && path[0] === '/') path = path.replace('/', '')
  const _port = config.APIPort
  const port = _port && parseInt(_port, 10) !== 80 ? `:${_port}` : ''
  const url = config.APIUrl
  return `${url}${port}${path}`
}

export function getSubmissionShareURL(id) {
  return `${process.env.REACT_APP_WEB_URL}filmpje/?id=${id}`
}

/**
 * Dispatches a general error
 * @param {object} asyncActions set of async actions
 * @param {object} response fetch response
 * @param {object} errorObject error object either parsed or from a js exception
 */
const dispatchError = (
  asyncActions,
  response = {},
  errorObject = {}
) => dispatch => {
  // Sometimes we get the error property as an Object sometimes as a String.
  let message = _.isObject(errorObject.error)
    ? Object.values(errorObject.error)[0]
    : _.get(errorObject, 'error', 'Error')
  // errorObject might be [{error: "message"}]
  if (_.isObject(message)) {
    message = _.get(message, 'error', 'Error')
  }
  const code = _.get(errorObject, 'code', 'unknown')
  const status =
    _.get(errorObject, 'status', '') || _.get(response, 'status', 'unknown')

  const ex = new Error(message)
  ex.code = code
  ex.status = status

  const dispatchedError = {}
  dispatchedError.message = message
  dispatchedError.code = code
  dispatchedError.status = status

  dispatch(asyncActions.ERROR(dispatchedError))

  return Promise.reject(ex)
}

export const getResponseStatus = response => {
  const notFound =
    response.statusText === 'Not Found' || response.status === 404
  const unauthorized =
    response.statusText === 'Unauthorized' || response.status === 403
  const statusOK =
    response.statusText === 'OK' || response.status === 200
  const noContent =
    response.statusText === 'No Content' || response.status === 204
  const responseOk =
    response.ok

  return {
    unauthorized,
    noContent,
    notFound,
    statusOK,
    responseOk,
  }
}

export const getResponseData = response => {
  let responseData = Promise.resolve(null)
  try {
    if (response.json) {
      responseData = response.json().catch(ex => null)
    }
  } catch (ex) {
    responseData = Promise.resolve(null)
  }
  return responseData
}

/**
 * Applies the auth token to the given url API path and returns the complete API URL
 * @param {string} path API path
 * @param {object} contextParams Context params to obtain the token on server side
 * @return {string} API URL with authorization token
 */
export const getAuthURL = (path, contextParams = {}) => {
  // const { accessToken } = getAuth(contextParams)
  const parsedURI = uri(getAPIURL(path))
  const authURL = parsedURI.toString()

  // We check if the authorization token is already set
  // if (accessToken) {
  //   authURL = parsedURI.setSearch('access_token', accessToken).toString()
  // }

  return authURL
}

/**
 * Call a async fetch
 * @param {String} urlPath Path of the api to be appended, the base path its built using the env parameters plus the "/api" path
 * @param {Object} options Request options
 * @param {object} contextParams Initial props that help retrieving context information
 */
export const makeAsyncCall = async (urlPath, options = {}, contextParams) => {
  const { headers, ...resOptions } = options
  const { accessToken } = getAuth()

  const requestHeaders = {
    Accept: 'application/json',
    ...headers
  }

  if (accessToken) {
    requestHeaders.Authorization = `Bearer ${accessToken}`
  }

  const fetchOptions = {
    method: 'GET',
    headers: requestHeaders,
    // credentials: 'include',
    ...resOptions
  }

  const URL = getAuthURL(urlPath, contextParams)

  // Fetch data
  return fetch(URL, fetchOptions)
}

/**
 * Create a async fetch dispatchable function using a set of async actions
 * @param {Object} asyncActions Store actions created with "createAsyncActions" method
 * @param {String} urlPath Request URL
 * @param {Object} options Request options
 * @return {Promise} response promise
 */
export const asyncDispatch = (asyncActions, urlPath, options) => {
  return dispatch => {
    const response = Promise.resolve()
    try {
      // Dispatch START action
      dispatch(asyncActions.START())

      return makeAsyncCall(urlPath, options) // Get response
        .then(response =>
          getResponseData(response) // Get data
            .then(responseData => {
              const { responseOk } = getResponseStatus(response)
              if (responseOk) {
                // Dispatch DONE action
                dispatch(asyncActions.DONE(responseData))
                return responseData
              } else {
                return dispatch(
                  dispatchError(asyncActions, response, responseData)
                )
              }
            })
        )
    } catch (ex) {
      // Dispatch error
      return dispatch(dispatchError(asyncActions, response, ex))
    }
  }
}

export const resetableReducer = (
  asyncActions,
  options = {},
  initialValue = null
) =>
  createAsyncReducer(asyncActions, {
    reset: () => initialValue,
    ...options
  })
