export const formatMusician = (musician = {}) => {
  const { id, instrument, first_name: firstName, age } = musician || {}

  return {
    id,
    instrument: instrument && instrument.name,
    firstName,
    age
  }
}

export const formatMusicians = musicians => {
  return musicians.filter(m => !!m.instrument).map(formatMusician)
}

export const formatMetadata = metadata => {
  const { is_verified: isVerified, is_votable: isVotable, converted } = metadata
  return {
    isVerified,
    isVotable,
    converted
  }
}

export const formatSubmission = submission => {
  const {
    submission_id: id,
    full_thumb_url: thumbUrl,
    musicians,
    metadata,
    full_video_url: videoUrl,
    band_name: bandName,
    created_at: createdAt,
    ...rest
  } = submission

  return {
    id,
    videoUrl,
    thumbUrl,
    bandName,
    createdAt,
    musicians: formatMusicians(musicians),
    metadata: formatMetadata(metadata),
    ...rest
  }
}

export const formatSubmissions = items => {
  return items.map(formatSubmission)
}
