import { createAsyncReducer, createAsyncActions } from 'resynchronize'
import { asyncDispatch } from '../utils'

export const fetchInstruments = createAsyncActions('FETCH-INSTRUMENTS')

export const getInstruments = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(fetchInstruments, '/api/instruments?size=99&order[name]=ASC', {
      method: 'GET'
    })
  )
    .then(data => {
      const list = (data._embedded && data._embedded.resource_list) || []
      const instruments = list.map(item => ({
        label: item.name,
        value: item.id
      }))
      dispatch(fetchInstruments.DONE({ list: instruments }))
    })
    .catch(err => {
      console.log(err)
    })
}

export default createAsyncReducer(fetchInstruments)
