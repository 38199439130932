import { createAction, createReducer } from 'redux-starter-kit'
import { createAsyncActions } from 'resynchronize'
import { push, replace } from 'connected-react-router'

import { asyncDispatch } from '../utils'
import { tryUpdateSubmission } from './index'
import { showErrorFlash } from '../general'

export const addMusician = createAction('ADD-MUSICIAN')
export const initMusicians = createAction('INIT-MUSICIAN')
export const editMusician = createAction('EDIT-MUSICIAN')
export const deleteMusician = createAction('DELETE-MUSICIAN')

export const participationSelect = createAction('SET-PARTICIPATION-TYPE')
export const getParticipationType = createAction('GET-PARTICIPATION-TYPE')

export const saveMusicians = createAsyncActions('SAVE-MUSICIANS')
export const createBand = createAsyncActions('CREATE-BAND')

export const musiciansReducer = createReducer([], {
  [initMusicians]: (state, { payload = {} }) =>
    payload.musicians ? [...payload.musicians] : [],
  [addMusician]: (state, { payload }) => state.concat(payload),
  [deleteMusician]: (state, { payload }) =>
    state.filter(m => m.id !== payload.id),
  [editMusician]: (state, { payload }) =>
    state.map(m => {
      if (m.id === payload.id) return { ...m, ...payload }
      return m
    })
})

export const participationReducer = createReducer({}, {
  [participationSelect]: (state, { payload }) => {
    state.isGroup = payload
  },
  [getParticipationType]: (state, { payload }) => {
    return state.isGroup;
  },
})

export const tryCreateMusicians = payload => (dispatch, getState) => {
  const {
    submission: { info }
  } = getState()
  return dispatch(
    asyncDispatch(saveMusicians, `/api/submissions/${info.id}/musicians`, {
      method: 'POST',
      body: JSON.stringify({ ...payload }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      dispatch(addMusician({ ...data }))
      dispatch(replace('/video/musicians/list'))
    })
    .catch(err => {
      // TODO: update error messages
      console.log(err.message)
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const tryDeleteMusician = (payload = {}) => (dispatch, getState) => {
  const {
    submission: { info, musicians }
  } = getState()
  return dispatch(
    asyncDispatch(
      saveMusicians,
      `/api/submissions/${info.id}/musicians/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  )
    .then(data => {
      /**
       * This case scenario is when we have 2 musicians and 1 band
       * We delete 1 musician, and after we need to clear the band as well
       */
      const musiciansCountAllowBand = musicians.length - 1 >= 2
      dispatch(deleteMusician({ id: payload.id }))
      if (musiciansCountAllowBand) {
        dispatch(replace('/video/musicians/list'))
      } else {
        dispatch(
          tryUpdateSubmission({
            band_name: '',
            redirectTo: '/video/musicians/list'
          })
        )
      }
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const tryUpdateMusician = (payload = {}) => (dispatch, getState) => {
  const {
    submission: { info }
  } = getState()
  return dispatch(
    asyncDispatch(
      saveMusicians,
      `/api/submissions/${info.id}/musicians/${payload.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  )
    .then(data => {
      dispatch(editMusician({ ...data }))
      dispatch(replace('/video/musicians/list'))
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const tryDeleteAllMusicians = (payload = {}) => (dispatch, getState) => {
  const {
    submission: { info }
  } = getState()
  return dispatch(
    asyncDispatch(saveMusicians, `/api/submissions/${info.id}/musicians`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      dispatch(initMusicians({ musicians: [] }))
      dispatch(replace(payload.redirectTo || '/video/musicians/list'))
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const handleLess5Flow = (payload = {}) => (dispatch, getState) => {
  const {
    submission: { info, musicians }
  } = getState()
  if (info.band) {
    // Removing the band if the the musicians flow is selected
    const redirectTo = musicians.length
      ? 'video/musicians/list'
      : '/video/musicians/add'
    dispatch(tryUpdateSubmission({ band_name: '', redirectTo }))
  } else {
    dispatch(push('/video/musicians/add'))
  }
}

export const handleMore5Flow = (payload = {}) => (dispatch, getState) => {
  const {
    submission: { musicians }
  } = getState()
  if (musicians.length) {
    dispatch(tryDeleteAllMusicians({ redirectTo: '/video/band/add' }))
  } else {
    dispatch(push('/video/band/add'))
  }
}

// todo -> add group field to api (bool)
/* export const setParticipationChoice = (payload = {}) => (dispatch, getState) => {
  const {
    submission: { group }
  } = getState()
  return dispatch(
    asyncDispatch(saveMusicians, `/api/submissions/group`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      dispatch(initMusicians({ musicians: [] }))
      dispatch(replace(payload.redirectTo || '/video/musicians/list'))
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
} */

export const setIsGroup = (bool) => (dispatch, getState) => {
  dispatch(participationSelect(bool))
}

export const getIsGroup = () => (dispatch, getState) => {
  const state = getState()
  return dispatch(getParticipationType())
}

export const handleGroupSelect = (isGroup, isNextVideo) => (dispatch, getState) => {
  if (isGroup === undefined || isGroup === null) {
    return null
  }
  isNextVideo ? dispatch(push('/video/select')) : dispatch(push('/video/contact/info'))
  // else {
  //   // dispatch(push('/onboarding/step-1'))
  //   // dispatch(push('/video/contact/info'))
  // }
}
