import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'

import store, { history, persistor } from './state/store'
import * as serviceWorker from './serviceWorker'
const App = React.lazy(() => import('./App'))

const Loading = () => <div title='Loading' style={{ display: 'none' }} />

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </PersistGate>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV === 'production') {
  serviceWorker.register({
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', event => {
          if (event.target.state === 'activated') {
            window.location.reload()
          }
        })
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
      }
    }
  })
} else {
  serviceWorker.register()
}
