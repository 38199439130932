import Cookies from 'js-cookie'
import addSeconds from 'date-fns/addSeconds'

export const getCookie = (name) => {
  return Cookies.get(name) || null
}

export const getCookies = () => {
  return Cookies.get()
}

export const removeAuthCookies = () => {
  const authCookies = [
    'access_token',
    'refresh_token',
    'user_email',
    'user_id'
  ]

  authCookies.forEach(name => Cookies.remove(name, { path: '/' }))
}

export const setCookie = (property, value, maxAge = 31536000) => {
  const expires = addSeconds(new Date(), maxAge)
  const attributes = { path: '/', sameSite: 'strict', expires }
  Cookies.set(property, `${value}`, attributes)
}

export const setAuth = (
  { accessToken, refreshToken, userId, userEmail },
  maxAge
) => {
  setCookie('access_token', accessToken, maxAge)
  if (refreshToken) setCookie('refresh_token', refreshToken, maxAge)
  if (userId) setCookie('user_id', userId, maxAge)
  if (userEmail) setCookie('user_email', userEmail, maxAge)
}

export const getAuth = (initialProps = {}) => {
  const cookies = getCookies(initialProps)

  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    user_email: userEmail,
    user_id: userId
  } = cookies

  return { accessToken, refreshToken, userEmail, userId }
}
