import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from 'redux-starter-kit'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import general from './general'
import submission from './submission'
import submissions from './submissions'
import user from './user'

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['general', 'user', 'router', 'submissions']
}

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    general,
    submission,
    submissions,
    user
  })

const persistedReducer = persistReducer(persistConfig, rootReducer(history))

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({ serializableCheck: false }),
    routerMiddleware(history)
  ]
},
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__())

export const persistor = persistStore(store)

export default store
