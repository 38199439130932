import { createAsyncReducer, createAsyncActions } from 'resynchronize'
import { asyncDispatch } from '../utils'

export const fetchChallenges = createAsyncActions('FETCH-CHALLENGES')

export const getChallenges = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(fetchChallenges, '/api/challenges', {
      method: 'GET'
    })
  )
    .then(data => {
      const list = (data._embedded && data._embedded.resource_list) || []
      const challengesList = list.map(el => ({
        id: el.id,
        name: el.badge,
        title: el.title,
        description: el.description
      }))
      dispatch(fetchChallenges.DONE({ list: challengesList }))
    })
    .catch(err => {
      console.log(err)
    })
}

export default createAsyncReducer(fetchChallenges)
