/* global FormData */
import { push, replace } from 'connected-react-router'
import { combineReducers } from 'redux'
import { createAction, createReducer } from 'redux-starter-kit'
import {
  createAsyncActions,
  getAsyncProps,
  getPayload,
  isLoading
} from 'resynchronize'
import {
  getAuth as getCookieAuth,
  removeAuthCookies,
  setAuth as setCookieAuth
} from '../cookies'
import { hideFlash, showErrorFlash, showSuccessFlash } from '../general'
import { resetToBegining } from '../submission'
import { formatSubmissions } from '../submissions/formatter'
import { asyncDispatch, resetableReducer, makeAsyncCall, getResponseData, getResponseStatus } from '../utils'

const ApiChannelId = process.env.REACT_APP_CHANNEL_ID
const ApiClientId = process.env.REACT_APP_CLIENT_ID
const ApiClientSecret = process.env.REACT_APP_CLIENT_SECRET

export const actions = {
  setAuth: createAction('UPDATE-AUTH'),
  clearAuth: createAction('CLEAR-AUTH'),
  me: createAsyncActions('REFRESH-ME'),
  myInfo: createAsyncActions('MY-INFO'),
  confirm: createAsyncActions('CONFIRM'),
  login: createAsyncActions('LOGIN'),
  register: createAsyncActions('REGISTER'),
  resend: createAsyncActions('RESEND'),
  requestResetPassword: createAsyncActions('REQUEST-RESET-PASSWORD'),
  resetPassword: createAsyncActions('RESET-PASSWORD'),
  requestForgetMe: createAsyncActions('REQUEST-FORGET-ME'),
  forgetMe: createAsyncActions('FORGET-ME'),
  requestMyData: createAsyncActions('REQUEST-MY-DATA'),
  requestMySubmissions: createAsyncActions('REQUEST-MY-SUBMISSIONS')
}

const accessToken = createReducer(null, {
  [actions.setAuth]: (state, { payload }) => payload.accessToken,
  [actions.clearAuth]: () => null
})

const refreshToken = createReducer(null, {
  [actions.setAuth]: (state, { payload }) => payload.refreshToken,
  [actions.clearAuth]: () => null
})

const setAuth = (authTokens, expiresIn) => dispatch => {
  if (expiresIn) setCookieAuth(authTokens, expiresIn)
  dispatch(actions.setAuth(authTokens))
}

const clearAuth = () => dispatch => {
  removeAuthCookies()
  dispatch(actions.clearAuth())
  dispatch(actions.me.DONE({}))
}

export const tryLogin = (credentials = {}) => (dispatch, getState) => {
  const formdata = new FormData()
  formdata.append('username', credentials.email)
  formdata.append('password', credentials.password)
  formdata.append('client_id', ApiClientId)
  formdata.append('client_secret', ApiClientSecret)
  formdata.append('grant_type', 'password')

  return dispatch(
    asyncDispatch(actions.login, 'oauth/v2/token', {
      method: 'POST',
      body: formdata
    })
  )
    .then(data => {
      const {
        user,
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn
      } = data

      const { router } = getState()
      const userConfirmed = user && user.enabled
      const nextScreen = (router.location.state && router.location.state.prevLocation) ||
        '/participation'

      dispatch(hideFlash())
      dispatch(setAuth({ accessToken, refreshToken }, expiresIn))
      dispatch(replace(userConfirmed ? nextScreen : '/auth/confirm/email'))
      dispatch(replace('/participation'))
    })
    .catch(err => {
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const tryRegister = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(actions.register, 'api/users/register', {
      method: 'POST',
      body: JSON.stringify({
        fos_user_registration_form: {
          channel: ApiChannelId,
          email: payload.email,
          username: payload.email,
          plainPassword: {
            first: payload.password,
            second: payload.password
          }
        },
        first_name: payload.firstName,
        middle_name: payload.middleName,
        last_name: payload.lastName
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      dispatch(replace('/auth/confirm/email'))
    })
    .catch(err => {
      const message =
        err.message === 'fos_user.email.already_used'
          ? 'EMAIL_ALREADY_EXISTS'
          : err.message || 'Error'
      dispatch(showErrorFlash({ message }))
    })
}

export const tryAddMissingInfo = (payload = {}) => (dispatch, getState) => {
  return dispatch(
    asyncDispatch(actions.myInfo, 'api/me', {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  ).catch(err => {
    // TODO: update error messages
    dispatch(showErrorFlash({ message: err.message }))
  })
}

export const tryAddInfo = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(actions.myInfo, 'api/me', {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      // the attribute enabled is moved inside the { user: .... }
      dispatch(push('/onboarding/step-1'))
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
}

// Step 1 of reseting the password: Entering the email
export const tryRequestPasswordReset = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(
      actions.requestResetPassword,
      `api/users/password/request/${payload.email}?channel_id=${ApiChannelId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  )
    .then(data => {
      // the attribute enabled is moved inside the { user: .... }
      dispatch(actions.requestResetPassword.DONE({ email: payload.email }))
      dispatch(push('/auth/password/confirm-email'))
    })
    .catch(err => {
      const message =
        err.message === 'NOT_FOUND' ? 'USER_NOT_FOUND' : err.message
      dispatch(showErrorFlash({ message }))
    })
}

// Step 2 of reseting the password: Entering the passwords
export const tryResetPassword = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(
      actions.resetPassword,
      `api/users/password/reset/${payload.token}`,
      {
        method: 'POST',
        body: JSON.stringify({
          fos_user_resetting_form: {
            plainPassword: {
              first: payload.password,
              second: payload.passwordConfirm
            }
          }
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  )
    .then(data => {
      // the attribute enabled is moved inside the { user: .... }
      dispatch(showSuccessFlash({ message: 'password changed' }))
      dispatch(replace('/auth/login'))
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const tryResend = () => (dispatch, getState) => {
  const { user } = getState()
  const username = (getPayload(user.me) || {}).username
  return dispatch(
    asyncDispatch(
      actions.resend,
      `/api/users/confirm/resend/${username}?channel_id=${ApiChannelId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  ).catch(err => {
    // TODO: update error messages
    dispatch(showErrorFlash({ message: err.message }))
  })
}

export const getCredentials = cb => (dispatch, getState) => {
  const { userId } = getCookieAuth()
  const {
    user: { credentials }
  } = getState()

  if (userId && !isLoading(credentials)) {
    dispatch(asyncDispatch(actions.getCredentials, `Accounts/${userId}`)).then(
      () => cb()
    )
  }
}

export const refreshUserFromToken = () => (dispatch, getState) => {
  const { accessToken, refreshToken } = getCookieAuth()

  if (accessToken) {
    dispatch(setAuth({ accessToken, refreshToken }))

    const formdata = new FormData()
    formdata.append('refresh_token', refreshToken)
    formdata.append('client_id', ApiClientId)
    formdata.append('client_secret', ApiClientSecret)
    formdata.append('grant_type', 'refresh_token')

    return dispatch(
      asyncDispatch(actions.me, 'oauth/v2/token', {
        method: 'POST',
        body: formdata
      })
    )
      .then(data => {
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_in: expiresIn
        } = data

        dispatch(setAuth({ accessToken, refreshToken }, expiresIn))
      })
      .catch(err => {
        dispatch(clearAuth())
        dispatch(showErrorFlash({ message: err.message }))
      })
  } else {
    dispatch(clearAuth())
    return Promise.resolve()
  }
}

export const confirmCode = code => dispatch => {
  return dispatch(
    asyncDispatch(actions.confirm, `api/users/confirm/${code}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn
      } = data

      dispatch(
        setAuth(
          {
            accessToken,
            refreshToken
          },
          expiresIn
        )
      )

      dispatch(refreshUserFromToken()).then(() => {
        dispatch(replace('/video/contact/info'))
      })
      // @TODO redirect to somewhere!
    })
    .catch(() => {
      console.log('Invalid code')
    })
}

export const requestMySubmissions = () => (dispatch, getState) => {
  return dispatch(
    asyncDispatch(actions.requestMySubmissions, '/api/me/submissions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  ).catch(err => {
    console.warn(err)
  })
}

export const requestMyData = () => dispatch => {
  return dispatch(
    asyncDispatch(
      actions.requestMyData,
      `api/me/personalInfo?channel_id=${ApiChannelId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  )
    .then(data => {
      dispatch(push('/user/download-data'))
      dispatch(actions.requestMyData.RESET())
    })
    .catch(err => {
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const requestforgetMe = () => dispatch => {
  return dispatch(
    asyncDispatch(
      actions.requestForgetMe,
      `api/me/forget?channel_id=${ApiChannelId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  )
    .then(data => {
      dispatch(push('/user/forget'))
      dispatch(actions.requestForgetMe.RESET())
    })
    .catch(err => {
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const tryForgetMe = code => (dispatch, getState) => {
  const { user } = getState()
  const { loading, done, error } = getAsyncProps(user.forgetMe)

  if (!loading && !done && !error) {
    return dispatch(
      asyncDispatch(actions.forgetMe, `api/me/delete/${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    )
      .then(data => {
        dispatch(logout('/'))
        dispatch(showSuccessFlash({ message: 'account deleted' }))
      })
      .catch(() => {
        dispatch(logout('/'))
        console.log('Invalid token')
      })
  }
}

export const logout = path => dispatch => {
  dispatch(resetToBegining())
  dispatch(clearAuth())
  dispatch(actions.me.RESET())
  dispatch(actions.myInfo.RESET())
  dispatch(actions.confirm.RESET())
  dispatch(actions.resend.RESET())
  dispatch(actions.register.RESET())
  dispatch(actions.login.RESET())
  dispatch(actions.resetPassword.RESET())
  dispatch(actions.requestResetPassword.RESET())
  dispatch(actions.forgetMe.RESET())
  dispatch(actions.requestMyData.RESET())
  dispatch(actions.requestForgetMe.RESET())
  dispatch(replace(path || '/auth/login'))
}

export const checkEmailExists = async (urlPath, options) => {
  try {
    return makeAsyncCall(urlPath, options) // Get response
      .then(response => {
        return getResponseData(response).then(responseData => {
          return { res: responseData, code: response.status }
        })
      }
      )
  } catch (error) {
    return { res: null, code: 500 }
  }
}

export const isZipCodeAllowed = async (country, zipCode, options) => {
  const COMPETITION_ID = process.env.REACT_APP_COMPETITION_ID
  const urlPath = `api/roundPerZips/find/${country}/${zipCode}/${COMPETITION_ID}`

  try {
    return makeAsyncCall(urlPath, options) // Get response
      .then(response => {
        return getResponseData(response).then(responseData => {
          return { res: responseData, code: response.status }
        })
      }
      )
  } catch (error) {
    return { res: null, code: 500 }
  }
}

export default combineReducers({
  auth: combineReducers({
    accessToken,
    refreshToken
  }),
  me: resetableReducer(
    [actions.login, actions.me],
    {
      done: (state, { type, payload }) => {
        const { user } = payload || {}
        switch (type) {
          case actions.me.DONE.type:
          case actions.login.DONE.type: {
            const { user_info: userInfo, ...rest } = user || {}
            return {
              ...rest
            }
          }
          default: {
            return { ...user }
          }
        }
      }
    },
    {}
  ),
  info: resetableReducer(
    [actions.login, actions.me, actions.register, actions.myInfo],
    {
      done: (state, { type, payload }) => {
        const { user, ...rest } = payload || {}

        switch (type) {
          case actions.me.DONE.type:
          case actions.login.DONE.type: {
            const { user_info: userInfo } = user || {}
            return {
              ...userInfo
            }
          }
          default: {
            return { ...rest }
          }
        }
      }
    }
  ),
  confirm: resetableReducer(actions.confirm),
  resend: resetableReducer(actions.resend),
  forgetMe: resetableReducer([actions.requestForgetMe, actions.forgetMe]),
  downloadData: resetableReducer(actions.requestMyData),
  resetPassword: resetableReducer(
    [actions.requestResetPassword, actions.resetPassword],
    {
      done: (state, { type, payload }) => {
        switch (type) {
          case actions.requestResetPassword.DONE.type: {
            return payload
          }
          default: {
            return state
          }
        }
      }
    }
  ),
  mySubmissions: resetableReducer(actions.requestMySubmissions, {
    done: (state, { payload }) => {
      return formatSubmissions(payload)
    },
    error: (state, action) => [],
    reset: () => []
  }),
  login: resetableReducer(actions.login),
  register: resetableReducer(actions.register, {
    done: (state, { payload }) => {
      const { id, email, enabled } = payload
      return { id, email, enabled }
    }
  })
})
