import { combineReducers, createAction, createReducer } from 'redux-starter-kit'
import {
  createAsyncActions,
  createAsyncReducer,
  getAsyncProps
} from 'resynchronize'
import { scrollTo } from '../general'
import { asyncDispatch } from '../utils'
import { formatSubmissions } from './formatter'

const SEARCH_TIME = 300

export const actions = {
  list: createAsyncActions('SUBMISSION-LIST'),
  scroll: createAction('SUBMISSION-LIST-SCROLL'),
  loadMore: createAction('SUBMISSION-LOAD-MORE'),
  musicianSearch: createAction('MUSICIAN-SEARCH')
  // setSubmissionsWave: createAction('SET-SUBMISSIONS-WAVE')
}

const PAGE_SIZE = 5
const CHANNEL_ID = process.env.REACT_APP_CHANNEL_ID

export const getList = (force = false) => (dispatch, getState) => {
  const {
    general: { rounds },
    submissions: {
      list: {
        items,
        search: { musician }
      }
    }
  } = getState()
  const { loading, done } = getAsyncProps(items)
  const { submissionlistEnd, submissionlistStart } = rounds.payload
  const year = new Date(submissionlistStart).getFullYear()

  dispatch(actions.list.RESET())
  dispatch(recoverScroll())

  if ((!loading && !done) || force) {
    return dispatch(
      asyncDispatch(
        actions.list,
        `api/submissions?page=1&size=${PAGE_SIZE}&musician=${musician}&year=${year}&filter[channel]=${CHANNEL_ID}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
    ).catch(ex => {
      console.warn(ex)
    })
  }
}

export const getListNextPage = (e) => (dispatch, getState) => {
  const {
    general: { rounds },
    submissions: {
      list: {
        items,
        search: { musician },
        page,
        loadMore
      }
    }
  } = getState()
  const { loading } = getAsyncProps(items)
  const { number = 1, size = PAGE_SIZE, totalPages = 1 } = page || {}
  const { submissionlistEnd, submissionlistStart } = rounds.payload
  const year = new Date(submissionlistStart).getFullYear()

  if (!loading && number < totalPages && loadMore) {
    return dispatch(
      asyncDispatch(
        actions.list,
        `api/submissions?page=${number +
        1}&size=${size}&musician=${musician}&year=${year}&filter[channel]=${CHANNEL_ID}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
    )
      .then(() => {
        dispatch(getListNextPage())
      })
      .catch(ex => {
        console.warn(ex)
      })
  }
}

const items = createAsyncReducer(actions.list, {
  done: (state, { payload }) => {
    const {
      _embedded: { resource_list: items }
    } = payload /* eslint-disable-line */
    const previousItems = state || []
    return [...previousItems, ...formatSubmissions(items)]
  },
  reset: () => []
})

const page = createReducer(
  { size: 0, totalPages: 0, numbre: 0 },
  {
    [actions.list.DONE]: (state, { payload }) => {
      const {
        _page: { size, number, total_pages: totalPages }
      } = payload /* eslint-disable-line */
      return {
        size: parseInt(size, 10),
        totalPages: parseInt(totalPages, 10),
        number: parseInt(number, 10)
      }
    }
  }
)

export const setScroll = () => dispatch => {
  const body = document.querySelector('#root')
  if (body) {
    dispatch(actions.scroll(body.scrollTop))
  }
}

const recoverScroll = () => (dispatch, getState) => {
  const {
    submissions: {
      list: { scroll }
    }
  } = getState()
  dispatch(scrollTo(scroll))
}

export const clearScroll = () => dispatch => {
  dispatch(actions.scroll(0))
}

let searchTimeout = null

export const tryMusicianSearch = musician => dispatch => {
  dispatch(actions.list.RESET())
  dispatch(actions.list.START())
  dispatch(actions.musicianSearch(musician))
  dispatch(clearScroll())

  if (searchTimeout) clearTimeout(searchTimeout)
  searchTimeout = setTimeout(() => {
    dispatch(getList(true))
  }, SEARCH_TIME)
}

export const setLoadMore = payload => dispatch => {
  dispatch(actions.loadMore(payload))
}

// export const setSubmissionsWave = payload => dispatch => {
//   dispatch(actions.setSubmissionsWave(payload))
// }

const scroll = createReducer(0, {
  [actions.scroll]: (state, { payload }) => payload
})

const loadMore = createReducer(false, {
  [actions.loadMore]: (state, { payload }) => payload || false
})

const musician = createReducer('', {
  [actions.musicianSearch]: (state, { payload }) => payload
})

// const submissionsWave = createReducer(1, {
//   [actions.setSubmissionsWave]: (state, { payload }) => payload
// })

const search = combineReducers({
  musician
})

export default combineReducers({
  items,
  loadMore,
  page,
  scroll,
  search
  // submissionsWave
})
