import { isFuture, isPast } from 'date-fns'
import { get } from 'lodash'
import { createAsyncActions, createAsyncReducer } from 'resynchronize'
import { asyncDispatch } from '../utils'

const CHANNEL_ID = process.env.REACT_APP_CHANNEL_ID
const COMPETITION_ID = process.env.REACT_APP_COMPETITION_ID
export const fetchAllRounds = createAsyncActions('FETCH-ALL-ROUNDS')
export const fetchRounds = createAsyncActions('FETCH-ROUNDS')

const defaultRoundInfo = {
  canSubmit: false,
  canVote: false,
  submissionStart: null,
  submissionEnd: null,
  voteStart: null,
  voteEnd: null,
  registrationStart: null,
  registrationEnd: null,
  submissionlistStart: null,
  submissionlistEnd: null,
  canRegister: false,
  canSeeSubmissions: false,
  round_id: null
}

export const getAllRounds = () => dispatch => {
  return dispatch(
    asyncDispatch(
      fetchAllRounds,
      `/api/rounds?filter[competition]=${COMPETITION_ID}&order[submissionStart]=DESC&size=2`,
      {
        method: 'GET'
      }
    )
  ).catch(err => {
    console.warn(err)
  })
}

export const getRounds = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(
      fetchRounds,
      `/api/rounds/currentRound?channel_id=${CHANNEL_ID}`,
      {
        method: 'GET'
      }
    )
  ).catch(err => {
    console.warn(err)
  })
}

const formatRound = (round) => {
  if (round) {
    const {
      submission_start: submissionStart,
      submission_end: submissionEnd,
      vote_start: voteStart,
      vote_end: voteEnd,
      id: roundId
    } = round
    return {
      roundId,
      submissionStart,
      submissionEnd,
      voteStart,
      voteEnd,
      canSubmit:
        isPast(new Date(submissionStart)) && isFuture(new Date(submissionEnd)),
      canVote: isPast(new Date(voteStart)) && isFuture(new Date(voteEnd))
    }
  } else {
    return { ...defaultRoundInfo }
  }
}

const formatConfig = (config = []) => {
  const {
    registration_startdate: registrationStart,
    registration_enddate: registrationEnd,
    submissionlist_startdate: submissionlistStart,
    submissionlist_enddate: submissionlistEnd,
    min_age,
    max_age
  } = config.reduce((acc, curr) => {
    acc[curr.name] = curr.data
    return acc
  }, {})

  return {
    registrationStart,
    registrationEnd,
    submissionlistStart,
    submissionlistEnd,
    registrationInPast: isPast(new Date(registrationStart)),
    registrationInFuture: isFuture(new Date(registrationEnd)),
    canRegister:
      isPast(new Date(registrationStart)) &&
      (!registrationEnd || isFuture(new Date(registrationEnd))),
    canSeeSubmissions:
      isPast(new Date(submissionlistStart)) &&
      (!submissionlistEnd || isFuture(new Date(submissionlistEnd))),
    minAge: +min_age,
    maxAge: +max_age
  }
}

export default createAsyncReducer(fetchRounds, {
  done: (state, { payload }) => {
    const round = formatRound(get(payload, 'current_round'))
    const config = formatConfig(get(payload, 'config'))
    return { ...round, ...config }
  },
  error: (state, action) => ({ ...defaultRoundInfo })
})

export const allRounds = createAsyncReducer(fetchAllRounds, {
  done: (state, { payload }) => {
    const [round2, round1] = payload._embedded.resource_list
    const keys = [
      'submission_end',
      'submission_start',
      'vote_end',
      'vote_start'
    ]
    const extract = round =>
      keys.reduce((acc, key) => ({ ...acc, [key]: round[key] }), {})
    return [round1, round2].map(extract)
  },
  error: (state, action) => []
})
