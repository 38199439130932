import { combineReducers, createAction } from 'redux-starter-kit'
import { replace } from 'connected-react-router'
import {
  createAsyncActions,
  createAsyncReducer,
  getAsyncProps
} from 'resynchronize'

import { asyncDispatch } from '../utils'
import { showErrorFlash } from '../general'

export const actions = {
  send: createAsyncActions('SEND-SUBMISSION-VOTE'),
  setSender: createAction('SET-VOTE-SENDER'),
  verify: createAsyncActions('VERIFY-SUBMISSION-VOTE')
}

export const sendVote = ({ email }, id) => (dispatch, getState) => {
  const {
    submissions: { vote: { send } }
  } = getState()
  const { loading } = getAsyncProps(send)

  if (!loading) {
    return dispatch(
      asyncDispatch(actions.send, 'api/votes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          vote_type: 'like',
          vote_value: '1',
          submission: id,
          channel: process.env.REACT_APP_CHANNEL_ID
        })
      })
    ).then(() => {
      dispatch(replace(`/vote/sent/${id}`))
    }).catch(ex => {
      console.warn(ex)
      dispatch(showErrorFlash({ message: 'That email already voted this video' }))
    })
  }
}

export const verifyVote = (code) => (dispatch, getState) => {
  const {
    submissions: { vote: { verify } }
  } = getState()
  const { loading } = getAsyncProps(verify)

  if (!loading) {
    return dispatch(
      asyncDispatch(actions.verify, `/api/vote/confirm/${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).then(() => {
      dispatch(replace('/vote/confirmed'))
    }).catch(ex => {
      console.warn(ex)
      dispatch(showErrorFlash({ message: 'The vote link is not valid any longer, try to vote another video!' }))
      dispatch(replace('/submissions'))
    })
  }
}

const send = createAsyncReducer(actions.send, {
  done: (state, { payload }) => payload
})

const verify = createAsyncReducer(actions.verify, {
  done: (state, { payload }) => payload
})

export default combineReducers({
  send,
  verify
})
