/* global FormData, XMLHttpRequest */

import { combineReducers, createReducer, createAction } from 'redux-starter-kit'
import { createAsyncActions } from 'resynchronize'
import { get, isString } from 'lodash'
import { push, replace } from 'connected-react-router'

import { asyncDispatch, getAuthURL, resetableReducer } from '../utils'
import { getAuth } from '../cookies'
import { initMusicians, saveMusicians, musiciansReducer, participationReducer } from './participants'
import { showErrorFlash } from '../general'

const ApiChannelId = process.env.REACT_APP_CHANNEL_ID
const ApiBaseUrl = process.env.REACT_APP_BASE_URL

export const uploadVideo = createAsyncActions('CREATE-VIDEO')
export const saveSubmission = createAsyncActions('SAVE-SUBMISSION')

export const initVideo = createAction('INIT-VIDEO')
export const cancelUpload = createAction('CANCEL-VIDEO-UPLOAD')
export const initInfo = createAction('INIT-SUBMISSION-INFO')
export const updateSubmissionInfo = createAction('UPDATE-SUBMISSION-INFO')
export const uploadProgress = createAction('VIDEO-UPLOAD-PROGRESS')
export const uploadError = createAction('VIDEO-UPLOAD-ERROR')
export const uploadSuccess = createAction('VIDEO-UPLOAD-SUCCESS')

export const takeSnapshot = createAction('TAKE-SNAPSHOT')

// INFO reducer (Holds submissions used information)
const infoInitialState = {
  title: null,
  band: null,
  description: null,
  videoId: null,
  id: null,
  videoUrl: null,
  videoLocalUrl: null,
  challenges: [],
  is_own_composition: null
}

const info = createReducer(infoInitialState, {
  [initInfo]: state => ({ ...infoInitialState }),
  [updateSubmissionInfo]: (state, { payload }) => ({
    ...state,
    ...payload
  })
})

// Video Uploading reducer (Holds uploading progress)
const videoInitialState = {
  status: null,
  data: null,
  error: null,
  progress: 0,
  snapshot: null
}

// Holds the upload request
let xhr = null

const video = createReducer(videoInitialState, {
  [initVideo]: state => ({ ...videoInitialState }),
  [cancelUpload]: state => ({ ...state, cancel: true }),
  [uploadProgress]: (state, { payload }) => ({
    ...videoInitialState,
    status: 'PROGRESS',
    progress: payload.progress
  }),
  [uploadError]: (state, { payload }) => ({
    ...state,
    status: 'ERROR',
    error: payload.statusText
  }),
  [uploadSuccess]: (state, { payload }) => ({
    ...state,
    status: 'DONE',
    data: payload
  }),
  [takeSnapshot]: (state, { payload }) => ({
    ...state,
    snapshot: payload
  })
})

export const tryCreateSubmission = (payload = {}) => (dispatch, getState) => {
  return dispatch(
    asyncDispatch(saveSubmission, 'api/submissions', {
      method: 'POST',
      body: JSON.stringify({
        channel: ApiChannelId,
        converted: 0,
        is_verified: 0,
        is_own_composition: 0,
        is_theme_submission: 0
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      /*  console.log(getState()) */
      dispatch(tryUploadVideo(payload, data))
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
      dispatch(replace('/video/select', { hideConfetti: true }))
    })
}

export const tryUploadVideo = (payload = {}, submissionData) => dispatch => {
  // Initialize Band, Musicians, Submission states
  dispatch(resetToBegining())

  dispatch(push('/video/upload/progress'))

  const ApiUrl = getAuthURL(
    `api/submissions/upload/${submissionData.submission_id}`
  )
  const { accessToken } = getAuth()

  const formData = new FormData()
  formData.append('video', payload.file)

  xhr = new XMLHttpRequest()
  const handleError = () => {
    const xhrError = get(xhr, 'response.error')
    const error = isString(xhrError) ? `${xhrError}` : 'SERVER_ERROR'
    dispatch(replace('/video/select', { hideConfetti: true }))
    dispatch(showErrorFlash({ message: error }))
  }

  xhr.open('PUT', ApiUrl, true)
  xhr.responseType = 'json'
  xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`)
  xhr.onload = () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      const data = xhr.response
      dispatch(uploadSuccess(data))
      dispatch(takeSnapshot(payload.res))
      dispatch(saveSubmission.DONE(submissionData))
      dispatch(
        updateSubmissionInfo({
          id: submissionData.submission_id,
          band: submissionData.band_name,
          is_own_composition: submissionData.is_own_composition,
          is_theme_submission: submissionData.is_theme_submission,
          title: submissionData.title,
          description: submissionData.description,
          videoId: data.id,
          videoUrl: data.web_path && `${ApiBaseUrl}${data.web_path}`
        })
      )
      dispatch(replace('/video/info/add'))
    } else {
      handleError()
    }
  }
  xhr.upload.onprogress = function (e) {
    const done = e.position || e.loaded
    const total = e.totalSize || e.total
    const perc = parseInt(Math.floor((done / total) * 1000) / 10)
    dispatch(uploadProgress({ progress: perc }))
  }
  xhr.onerror = handleError
  xhr.send(formData)
  return xhr
}

export const tryUpdateSubmission = ({ redirectTo, ...payload }) => (
  dispatch,
  getState
) => {
  const {
    submission: { info },
    submission
  } = getState()

  return dispatch(
    asyncDispatch(saveSubmission, `api/submissions/${info.id}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  )
    .then(data => {
      dispatch(saveSubmission.DONE(data))
      dispatch(
        updateSubmissionInfo({
          challenge_motivation: data.challenge_motivation || '',
          band: data.band_name,
          is_own_composition: data.is_own_composition,
          is_theme_submission: data.is_theme_submission,
          title: data.title,
          description: data.description,
          challenges: data.challenges,
          videoUrl: data.video_url && `${ApiBaseUrl}${data.video_url}`
        })
      )
      submission.participation.isGroup ? dispatch(push(redirectTo || '/video/preview')) : dispatch(push(redirectTo || '/video/musicians/add'))

    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const AbortUpload = () => dispatch => {
  if (xhr) {
    xhr.abort()
    dispatch(initVideo())
    dispatch(replace('/video/select', { hideConfetti: true }))
    xhr = null
  }
}

export const tryVerifySubmission = (payload = {}) => dispatch => {
  return dispatch(
    asyncDispatch(saveSubmission, `api/submissions/${payload.id}/verify/${payload.roundId}`, {
      method: 'GET'
    })
  )
    .then(data => {
      dispatch(push('/video/verified'))
      setTimeout(() => {
        dispatch(resetToBegining())
      }, 250)
    })
    .catch(err => {
      // TODO: update error messages
      dispatch(showErrorFlash({ message: err.message }))
    })
}

export const resetToBegining = () => dispatch => {
  dispatch(initMusicians())
  dispatch(initInfo())
  dispatch(initVideo())
  dispatch(saveSubmission.RESET())
  dispatch(saveMusicians.RESET())
}

export default combineReducers({
  save: resetableReducer(saveSubmission),
  saveMusicians: resetableReducer(saveMusicians),
  info,
  video,
  musicians: musiciansReducer,
  participation: participationReducer
})
