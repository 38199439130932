import { combineReducers } from 'redux-starter-kit'
import {
  createAsyncActions,
  createAsyncReducer,
  getAsyncProps
} from 'resynchronize'

import { asyncDispatch } from '../utils'

export const actions = {
  add: createAsyncActions('ADD-SUBMISSION-VIEW')
}

export const addView = (id) => (dispatch, getState) => {
  const {
    submissions: { views: { add } }
  } = getState()
  const { loading } = getAsyncProps(add)

  if (!loading) {
    return dispatch(
      asyncDispatch(actions.add, 'api/views', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          length: 99,
          submission: id,
          channel: process.env.REACT_APP_CHANNEL_ID
        })
      })
    ).catch(() => { })
  }
}

const add = createAsyncReducer(actions.add, {
  done: (state, { payload }) => payload
})

export default combineReducers({
  add
})
